import { addressBook } from '../../blockchain-addressbook';

const {
  fantom: fantomAddressBook,
  base: baseAddressBook,
} = addressBook;
export {

  fantomAddressBook,
  baseAddressBook,
};

export {
  vaultABI,
  bnbVaultABI,
  erc20ABI,
  strategyABI,
  multicallABI,
  govPoolABI,
  beefyUniV2ZapABI,
  uniswapV2PairABI,
  uniswapV2RouterABI,
  launchPoolABI,
  XpandrUnityABI,
} from './abi';
export { nativeCoins } from './native_coins';
export { fantomPools } from './vault/fantom_pools';
export { basePools } from './vault/base_pools';
export { fantomStakePools } from './stake/fantom_stake';
export { baseStakePools } from './stake/base_stake';
export { fantomZaps } from './zap/fantom_zaps';
export { baseZaps } from './zap/base_zaps';