export const basePools = [
  {
    id: 'equalizerbase-scale-pscale',
    name: 'SCALE-pSCALE',
    token: 'SCALE-pSCALE LP (SCALE)',
    tokenDescription: 'Scale',
    tokenAddress: '0x2dCE41906834E09bB28D5756F21c6EC32642f23C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpS-SCALE-pSCALE',
    earnedTokenAddress: '0x726D97d627Fd393751d0b92E7B8A0D9B4B548c83',
    earnContractAddress: '0x726D97d627Fd393751d0b92E7B8A0D9B4B548c83',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizerbase-scale-pscale',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scale',
    assets: ['SCALE', 'PSCALE'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://base.equalizer.exchange/liquidity/0x2dCE41906834E09bB28D5756F21c6EC32642f23C',
    buyTokenUrl:
      'https://base.equalizer.exchange/swap',
    createdAt: 9867297,
  }
];

