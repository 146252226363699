import { container } from 'assets/jss/material-kit-pro-react.js';

const appStyle = theme => ({
  '@global': {
    'html,body': {
      backgroundColor: theme.palette.background.default,
    },
    '#WEB3_CONNECT_MODAL_ID': {

      position: 'relative',
      zIndex: 2100,
    },
  },
  page: {
    background:
      "radial-gradient(circle, rgba(0,0,0,1) 10%, rgba(0,62,17,0.1) 30%, rgba(0,0,0,1) 100%)",

    minHeight: '100vh',
    position: 'relative',
    overflow: 'hidden',
  },
  container: {

    ...container,
    zIndex: 1,
  },
  children: {
    minHeight: '77vh',
  },
});

export default appStyle;
