//import { connectors } from 'web3modal';
import { indexBy } from './utils';
import WalletConnectProvider from '@walletconnect/web3-provider';
//import WalletLink from 'walletlink';
import { allNetworks } from '../../network';
import {
  nativeCoins,
  fantomPools,
  fantomStakePools,
  fantomAddressBook,
  fantomZaps,
  basePools,
  baseStakePools,
  baseAddressBook,
  baseZaps,
} from '../configure';

export const appNetworkId = window.REACT_APP_NETWORK_ID;

const networkTxUrls = {
  250: hash => `https://ftmscan.com/tx/${hash}`,
  8453: hash => `https://basescan.com/tx/${hash}`,
};

const networkFriendlyName = {
  250: 'Fantom',
  8453: 'Base',
};

const networkBuyUrls = {
  250: '',
  8453: '',
};

export const getNetworkCoin = () => {
  return nativeCoins.find(coin => coin.chainId === appNetworkId);
};

export const getNetworkPools = () => {
  switch (window.REACT_APP_NETWORK_ID) {

    case 250:
      return fantomPools;
    case 8453:
      return basePools;

      default:
      return [];
  }  
};

export const getNetworkVaults = (networkId = appNetworkId) => {
  switch (networkId) {

    case 250:
      return indexBy(fantomPools, 'id');
    case 8453:
      return indexBy(basePools, 'id');
  
      default:
      return {};
  }
};

export const getNetworkLaunchpools = (networkId = appNetworkId) => {
  switch (networkId) {

    case 250:
      return indexBy(fantomStakePools, 'id');
    case 8453:
      return indexBy(baseStakePools, 'id');

      default:
      return {};
    }
};

export const getNetworkTokens = () => {
  const chainId = window.REACT_APP_NETWORK_ID;
  switch (chainId) {

    case 250:
      return fantomAddressBook.tokens;
    case 8453:
      return baseAddressBook.tokens;
  
    default:
      throw new Error(
        `Create address book for chainId(${chainId}) first. Check out https://github.com/beefyfinance/address-book`
      );
  }
};

export const getNetworkBurnTokens = () => {
  switch (window.REACT_APP_NETWORK_ID) {

    case 250:
      return {};
    case 8453:
      return {};
    default:
      throw new Error(`Create address book for this chainId first.`);
  }
};

export const getNetworkZaps = () => {
  switch (window.REACT_APP_NETWORK_ID) {

    case 250:
      return fantomZaps;
    case 8453:
      return baseZaps;
      default:
      return [];
  }
};

export const getNetworkStables = () => {
  switch (window.REACT_APP_NETWORK_ID) {


    case 250:
      return ['USDC', 'USDT'];
    case 8453:
      return ['USDC', 'USDBC'];
      default:
      return [];
  }
};

export const getNetworkMulticall = () => {
  switch (window.REACT_APP_NETWORK_ID) {

    case 250:
      return '0xC9F6b1B53E056fd04bE5a197ce4B2423d456B982';
    case 8453:
      return '0x9A5623805AB445De6B20ea59d6AB29648C0C462A';
  
      default:
      return '';
  }
};

export const getNetworkConnectors = t => {
  switch (window.REACT_APP_NETWORK_ID) {

    case 250:
      return {
        network: 'fantom',
        cacheProvider: true,
        providerOptions: {
          injected: {
            display: {
              name: 'MetaMask',
            },
          },
          'custom-wc-fantom': {
            display: {
              logo: require(`images/wallets/wallet-connect.svg`),
              name: 'Wallet Connect',
              description: t('Scan your WalletConnect to Connect'),
            },
            package: WalletConnectProvider,
            options: {
              rpc: {
                250: 'https://fantom.publicnode.com',
              },
            },
            connector: async (ProviderPackage, options) => {
              const provider = new ProviderPackage(options);

              await provider.enable();

              return provider;
            },
          },
        },
      };
      case 8453:
        return {
          network: 'base',
          cacheProvider: true,
          providerOptions: {
            injected: {
              display: {
                name: 'MetaMask',
              },
            },
            'custom-wc-base': {
              display: {
                logo: require(`images/wallets/wallet-connect.svg`),
                name: 'Wallet Connect',
                description: t('Scan your WalletConnect to Connect'),
              },
              package: WalletConnectProvider,
              options: {
                rpc: {
                  8453: 'https://base.publicnode.com',
                },
              },
              connector: async (ProviderPackage, options) => {
                const provider = new ProviderPackage(options);
  
                await provider.enable();
  
                return provider;
              },
            },
          },
        };
  
    default:
      return {};
  }

};

export const getNetworkTxUrl = networkTxUrls[window.REACT_APP_NETWORK_ID];
export const getNetworkFriendlyName = (networkId = window.REACT_APP_NETWORK_ID) =>
  networkFriendlyName[networkId];
export const getNetworkBuyUrl = (networkId = window.REACT_APP_NETWORK_ID) =>
  networkBuyUrls[networkId];
export const getNetworkAppUrl = (networkId = window.REACT_APP_NETWORK_ID) =>
  window.location.protocol +
  '//' +
  window.location.host +
  window.location.pathname +
  '#' +
  allNetworks.find(n => n.id === networkId)?.hash;

export const launchpools = getNetworkLaunchpools();
export const vaults = getNetworkVaults();
