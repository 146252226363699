
var __createBinding = (this && this.__createBinding) || (Object.create ? (function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function () { return m[k]; } });
}) : (function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function (m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.addressBookByChainId = exports.addressBook = void 0;
//var telos_1 = require("./telos");
var fantom_1 = require("./fantom");
var base_1 = require("./base");
var chainid_1 = require("../types/chainid");
__exportStar(require("../types/chainid"), exports);
var _addressBook = {
    base: base_1.base,
    fantom: fantom_1.fantom

};
var _addressBookByChainId = (_a = {},
    _a[chainid_1.ChainId.base] = base_1.base,
    _a[chainid_1.ChainId.fantom] = fantom_1.fantom,

    _a);
exports.addressBook = _addressBook;
exports.addressBookByChainId = _addressBookByChainId;
