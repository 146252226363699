Object.defineProperty(exports, "__esModule", { value: true });
exports.xpandrfinancial = void 0;
exports.xpandrfinancial = {
    devMultisig: '',
    strategyOwner: '0x847298aC8C28A9D66859E750456b92C2A67b876D',
    vaultOwner: '0x4560a83b7eED32EB78C48A5bedE9B608F3184df0',
    treasury: '0xe6CcE165Aa3e52B2cC55F17b1dBC6A8fe5D66610',
    xpandrFeeRecipient: '',
    multicall: '0xC9F6b1B53E056fd04bE5a197ce4B2423d456B982',
};
