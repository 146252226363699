//import { govPoolABI } from '../abi';

export const baseStakePools = [
  /*

  {
    id: 'XpZ_ZAP',
    name: 'Zappy Single',
    logo: 'single-assets/beQI.png',
    token: 'ZAP',
    tokenDecimals: 18,
    tokenAddress: '0xBA809e44417fBa0ef421C64bDb1a4Ce0F6a9D2Bc',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZAP',
    earnedToken: 'XpZ-ZAP',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xA3499d2099880E20c4d78537B1EC032fC7460457',
    earnContractAddress: '0xA3499d2099880E20c4d78537B1EC032fC7460457',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZAP',
    partnership: false,
    status: 'active',
    fixedStatus: true,
    partners: [
      {
        logo: 'stake/tombfinance/logo.png',
        background: 'stake/tombfinance/background.png',
        text: 'Tomb algorithmic token is the first project and the base foundation of an ecosystem that will bring utility and value for token holders. Designed by the Tomb.finance team as the first algorithmic token pegged to FTM instead of a stablecoin, on OPERA Fantom. It works around a solution that can adjust the token supply to move the price of the token itself up or down, in the direction of a target price to bring programmability and stability to the price. Inspired by Basis original idea, and its predecessors improvements (bDollar and soup), Tomb is a multi-token protocol that consists of the following tokens: Tomb (TOMB), Tomb Shares (TSHARE), and Tomb Bonds (TBOND).\n',
        website: 'https://tomb.finance',
        social: {
          telegram: 'https://t.me/tombfinance',
          twitter: 'https://twitter.com/tombfinance',
        },
      },
    ],
  },
  */
];
