export const networkSettings = {
  
  250: {
    chainId: `0x${parseInt(250, 10).toString(16)}`,
    chainName: 'Fantom',
    nativeCurrency: {
      name: 'FANTOM',
      symbol: 'FTM',
      decimals: 18,
    },
    rpcUrls: ['https://fantom.publicnode.com'],
    blockExplorerUrls: ['https://www.ftmscan.com'],
  },
  8453: {
    chainId: `0x${parseInt(8453, 10).toString(16)}`,
    chainName: 'Base',
    nativeCurrency: {
      name: 'ETHER',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://developer-access-mainnet.base.org'],
    blockExplorerUrls: ['https://www.basescan.com'],
  },
};

export const networkSetup = chainId => {
  return new Promise((resolve, reject) => {
    const provider = window.ethereum;
    if (provider) {
      if (networkSettings.hasOwnProperty(chainId)) {
        provider
          .request({
            method: 'wallet_addEthereumChain',
            params: [networkSettings[chainId]],
          })
          .then(resolve)
          .catch(reject);
      } else {
        reject(new Error(`No network settings configured for chainId: '${chainId}'`));
      }
    } else {
      reject(new Error(`window.ethereum is '${typeof provider}'`));
    }
  });
};

export const getRpcUrl = () => {
  const settings = networkSettings[window.REACT_APP_NETWORK_ID];
  return settings.rpcUrls[~~(settings.rpcUrls.length * Math.random())];
};
