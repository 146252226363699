
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.tokens = void 0;
var WFTM = {
    name: 'FANTOM',
    address: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    symbol: 'WFTM',
    chainId: 250,
    decimals: 18,
    website: 'https://fantom.foundation/',
    description: '',
    logoURI: '',
};
var FTM = {
    name: 'FANTOM',
    address: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    symbol: 'FTM',
    chainId: 250,
    decimals: 18,
    website: 'https://fantom.foundation/',
    description: 'Fantom is a fast, high-throughput open-source smart contract platform for digital assets and dApps.',
    logoURI: 'https://raw.githubusercontent.com/zappy-finance/zappy-tokenlists/main/logos/0x9A271E3748F59222f5581BaE25250dAa5806b3F77/logo.png',
};
var axlUSDC = {
    name: 'AxelarUSD Coin',
    address: '0x1b6382dbdea11d97f24495c9a90b7c88469134a4',
    symbol: 'axlUSDC',
    chainId: 250,
    decimals: 6,
    website: '',
    description: 'USDC is a fully collateralized US dollar stablecoin. USDC is issued by regulated financial institutions, backed by fully reserved assets, redeemable on a 1:1 basis for US dollars.',
    logoURI: 'https://raw.githubusercontent.com/zappy-finance/zappy-tokenlists/main/logos/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b/logo.png',
};
var USDT = {
    name: 'Frapped USDT',
    address: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    symbol: 'USDT',
    chainId: 250,
    decimals: 6,
    website: 'https://tether.to/en/',
    description: 'Launched in 2014, Tether tokens (USD₮) pioneered the stablecoin model and are the most widely traded. Tether tokens offer the stability and simplicity of fiat currencies coupled with the innovative nature of blockchain technology, representing a perfect combination of both worlds.',
    logoURI: 'https://raw.githubusercontent.com/zappy-finance/zappy-tokenlists/main/logos/0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73/logo.png',
};
var BNB = {
    name: 'Binance Coin',
    address: '0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
    symbol: 'BNB',
    chainId: 250,
    decimals: 18,
    website: 'https://www.binance.com/',
    description: 'Binance Coin (BNB) is an exchange-based token created and issued by the cryptocurrency exchange Binance. Initially created on the Ethereum blockchain as an ERC-20 token in July 2017, BNB was migrated over to Binance Chain in February 2019 and became the native coin of the Binance Chain.',
    logoURI: 'https://raw.githubusercontent.com/zappy-finance/zappy-tokenlists/main/logos/0x2C78f1b70Ccf63CDEe49F9233e9fAa99D43AA07e/logo.png',
};
var ETH = {
    name: 'Wrapped Ether',
    address: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    symbol: 'ETH',
    chainId: 250,
    decimals: 18,
    website: 'https://ethereum.org/',
    description: 'The native currency that flows within the Ethereum economy is called Ether (ETH). Ether is typically used to pay for transaction fees called Gas, and it is the base currency of the network.',
    logoURI: 'https://raw.githubusercontent.com/zappy-finance/zappy-tokenlists/main/logos/0xfA9343C3897324496A05fC75abeD6bAC29f8A40f/logo.png',
};
var BTC = {
    name: 'Wrapped Bitcoin',
    address: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    symbol: 'BTC',
    chainId: 250,
    decimals: 18,
    website: 'https://wbtc.network/',
    description: 'Wrapped Bitcoin (WBTC) is the first ERC20 token backed 1:1 with Bitcoin. Completely transparent. 100% verifiable. Community led.',
    logoURI: 'https://ftmscan.com/token/images/wBTC_32.png',
};
var BAY = {
    name: 'Moon Bay',
    address: '0xd361474bB19C8b98870bb67F5759cDF277Dee7F9',
    symbol: 'BAY',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};
var EQUAL = {
    name: 'Equalizer',
    address: '0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6',
    symbol: 'EQUAL',
    chainId: 250,
    decimals: 18,
    website: 'https://equalizer.exchange/home',
    description: 'Equalizer was derived from the initial concept of Andre Cronje. The mechanism that was used to create a perpetual decentralized exchange was unique and ingenious. The launch of this platform is to take the concept and apply it to a more natural style DEX. Equalizer will become the trading mechanism for the Fantom network, utilizing the Solidly perpetual model with some tweaks to fee structures and emissions.',
    logoURI: '',
};
var WIGO = {
    name: 'WigoSwap',
    symbol: 'WIGO',
    address: '0xE992bEAb6659BFF447893641A378FbbF031C5bD6',
    chainId: 250,
    decimals: 18,
    website: 'https://wigoswap.io/',
    description:
        'WigoSwap is a DeFi hub with lifetime farming earnings powered by Gamified Burning Mechanism (GBM).',
    logoURI: 'https://wigoswap.io/images/tokens/0xE992bEAb6659BFF447893641A378FbbF031C5bD6.svg',
};
var BRUSH = {
    name: 'PaintSwap',
    symbol: 'BRUSH',
    address: '0x85dec8c4B2680793661bCA91a8F129607571863d',
    chainId: 250,
    decimals: 18,
    website: 'https://paintswap.finance/',
    description:
        'PaintSwap combines an open NFT marketplace with a decentralized exchange (AMM) on the Fantom Opera Network.',
    logoURI: 'https://assets.spookyswap.finance/tokens/BRUSH.png',
};
var CHILL = {
    name: 'ChillPill',
    symbol: 'CHILL',
    address: '0xe47d957F83F8887063150AaF7187411351643392',
    chainId: 250,
    decimals: 18,
    website: 'https://app.mummy.finance/#/trade?ref=beefy',
    description: '',
    logoURI: '',
};
var FGHST = {
    name: 'Fantom Ghost',
    symbol: 'FGHST',
    address: '0x8b7007E1D02d8387B7B4BC8C6172598780ae59B2',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};
var SKULL = {
    name: 'SkullSwap',
    symbol: 'SKULL',
    address: '0xfa5992A8A47aF7029e04eC6a95203AD3f301460b',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};
var KIRBY = {
    name: 'Blue Kirby',
    symbol: 'KIRBY',
    address: '0x97bdAfe3830734acF12Da25359674277fcc33729',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};
var FSONIC = {
    name: 'FantomSonicInu',
    symbol: 'KIRBY',
    address: '0x05e31a691405d06708A355C029599c12d5da8b28',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};
var PEQUAL = {
    name: 'Pantheon Equal',
    symbol: 'pEQUAL',
    address: '0xf773E8590A7411154E590a6D1E2648497c60ae6F',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};
var TOMBPLUS = {
    name: 'Tomb+',
    symbol: 'TOMB+',
    address: '0xE53aFA646d48E9EF68fCd559F2a598880a3f1370',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};
var FKNUCKLES = {
    name: 'Fantom Knuckles+',
    symbol: 'FKNUCKLES+',
    address: '0x1f008f9af47b387BDF67a25f2B8219942207298f',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};
var GMFTM = {
    name: 'Good Morning FTM',
    symbol: 'GMFTM',
    address: '0x454d4BaE7c2adab588d837aFF4Db16Db19d46A33',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};
var LZUSDC = {
    name: 'Layer Zero USDC',
    symbol: 'LZUSDC',
    address: '0x28a92dde19D9989F39A49905d7C9C2FAc7799bDf',
    chainId: 250,
    decimals: 6,
    website: '',
    description: '',
    logoURI: '',
};
var MIM = {
    name: 'Magic Internet Money',
    symbol: 'MIM',
    address: '0x82f0B8B456c1A451378467398982d4834b6829c1',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};
var GOAT = {
    name: 'GOAT',
    symbol: 'GOAT',
    address: '0xCD62f054eeE65c8D23fe2aDC8bae0443D4EC82dB',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};
var FTAILS = {
    name: 'fTAILS',
    symbol: 'FTAILS',
    address: '0x5cF90b977C86415a53CE3B7bE13b26f6abdDfee2',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};

var FBUX = {
    name: 'fBUX',
    symbol: 'FBUX',
    address: '0x1e2Ea3f3209D66647f959CF00627107e079B870d',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};

var LOVE = {
    name: 'LOVE',
    symbol: 'LOVE',
    address: '0xfB6d43096647D366992eD04F57ADaE8EA905aEe6',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};

var THC = {
    name: 'TinHatCat',
    symbol: 'THC',
    address: '0x479673391b3818f5e3ED2fa69A58e13d685BEcf6',
    chainId: 250,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};

var USDCe = {
    name: 'Wormhole USDC',
    symbol: 'USDC.e',
    address: '0x2F733095B80A04b38b0D10cC884524a3d09b836a',
    chainId: 250,
    decimals: 6,
    website: '',
    description: '',
    logoURI: '',
};

var _tokens = {
    WFTM: FTM,
    WNATIVE: FTM,
    FTM: FTM,
    axlUSDC: axlUSDC,
    USDT: USDT,
    BNB: BNB,
    ETH: ETH,
    BTC: BTC,
    //MATIC: MATIC,
    //AVAX: AVAX,
    EQUAL: EQUAL,
    //SPELL: SPELL,
    //BOO: BOO,
    //MAI: MAI,
    //LINK: LINK,
    MIM: MIM,
    //ICE: ICE,
    //FRAX: FRAX,
    //CRV: CRV,
    //BEETS: BEETS,
    //DEUS: DEUS,
    //DAI: DAI,
    //SUSHI: SUSHI,
    //MLP: MLP,
    //SHRAP: SHRAP,
    //FBOMB: FBOMB,
    //ANKR: ANKR,
    //ankrFTM: ankrFTM,
    //GEIST: GEIST,
    //xBOO: xBOO,
    BRUSH: BRUSH,
    WIGO: WIGO,
    //fBEETS: fBEETS,
    //DEI: DEI,
    //FXS: FXS,
    //LQDR: LQDR,
    //fUSDT: fUSDT,
    //MPX: MPX,
    //MCLB: MCLB,
    FGHST: FGHST,
    SKULL: SKULL,
    //RING: RING,
    CHILL: CHILL,
    //SOUL: SOUL,
    //dKNIGHT: dKNIGHT,
    //MMY: MMY,
    BAY: BAY,
    //SLM: SLM,
    //ELITE: ELITE,
    FSONIC: FSONIC,
    KIRBY: KIRBY,
    PEQUAL: PEQUAL,
    TOMBPLUS: TOMBPLUS,
    FKNUCKLES: FKNUCKLES,
    GMFTM: GMFTM,
    //WFTM: WFTM
    LZUSDC: LZUSDC,
    GOAT: GOAT,
    FTAILS: FTAILS,
    FBUX: FBUX,
    LOVE: LOVE,
    THC: THC,
    USDCe: USDCe
}
exports.tokens = _tokens;
