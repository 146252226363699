import Pools from 'features/vault/components/Pools/Pools';
import React from 'react';
import './App.css';

export default function HomePage() {


  return (

    <Pools fromPage="home" />


  );
}
