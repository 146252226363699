import { createTheme } from '@material-ui/core/styles';

const createThemeMode = () =>
  createTheme({
    palette: {
      type: 'dark',
      background: {
        paper: '#202020D9',
        primary: '#202020',
        secondary: 'rgba(90,143,105, 0.2)',
        extra: '#242332',
        dark: '#2B2A3D',
        paused: '#2B2A5A',
        retired: '#d32f2f',
        hover: 'rgba(8, 67, 18, 0.95)',
        overlay: 'rgba(0, 0, 0, 0.75)',
      },
      primary: {
        main: '#fff',
      },
      secondary: {
        main: '#fff',
      },
      text: {
        primary: '#fff',
        secondary: 'rgba(156, 156, 202, 1)',
        flipped: '#000',
      },
    },
    overrides: {
      MuiButton: {
        label: {
          color: '#fff',
        },
      },
      // for dropdown menu items
      MuiButtonBase: {
        root: {
          color: '#fff',
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color: 'rgba(8, 67, 18, 0.95)',
        },
        colorSecondary: {
          color: '#202020',
        },
      },
    },
  });

export default createThemeMode;
