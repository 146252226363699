
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.tokens = void 0;
var WETH = {
    name: 'Ethereum',
    address: '0x4200000000000000000000000000000000000006',
    symbol: 'WETH',
    chainId: 8453,
    decimals: 18,
    website: 'https://fantom.foundation/',
    description: '',
    logoURI: '',
};
var ETH = {
    name: 'Ethereum',
    address: '0x4200000000000000000000000000000000000006',
    symbol: 'ETH',
    chainId: 8453,
    decimals: 18,
    website: 'https://fantom.foundation/',
    description: 'Fantom is a fast, high-throughput open-source smart contract platform for digital assets and dApps.',
    logoURI: 'https://raw.githubusercontent.com/zappy-finance/zappy-tokenlists/main/logos/0x9A271E3748F59222f5581BaE258453dAa5806b3F77/logo.png',
};
var USDC = {
    name: 'USD Coin',
    address: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    symbol: 'USDC',
    chainId: 8453,
    decimals: 6,
    website: '',
    description: 'USDC is a fully collateralized US dollar stablecoin. USDC is issued by regulated financial institutions, backed by fully reserved assets, redeemable on a 1:1 basis for US dollars.',
    logoURI: 'https://raw.githubusercontent.com/zappy-finance/zappy-tokenlists/main/logos/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b/logo.png',
};
var USDBC = {
    name: 'CoinBase USD',
    address: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    symbol: 'USDBC',
    chainId: 8453,
    decimals: 6,
    website: 'https://tether.to/en/',
    description: 'Launched in 2014, Tether tokens (USD₮) pioneered the stablecoin model and are the most widely traded. Tether tokens offer the stability and simplicity of fiat currencies coupled with the innovative nature of blockchain technology, representing a perfect combination of both worlds.',
    logoURI: 'https://raw.githubusercontent.com/zappy-finance/zappy-tokenlists/main/logos/0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73/logo.png',
};
var BTC = {
    name: 'Wrapped Bitcoin',
    address: '0x1a35EE4640b0A3B87705B0A4B45D227Ba60Ca2ad',
    symbol: 'BTC',
    chainId: 8453,
    decimals: 18,
    website: 'https://wbtc.network/',
    description: 'Wrapped Bitcoin (WBTC) is the first ERC20 token backed 1:1 with Bitcoin. Completely transparent. 100% verifiable. Community led.',
    logoURI: 'https://ftmscan.com/token/images/wBTC_32.png',
};
var SCALE = {
    name: 'Scale',
    address: '0x54016a4848a38f257B6E96331F7404073Fd9c32C',
    symbol: 'SCALE',
    chainId: 8453,
    decimals: 18,
    website: 'https://equalizer.exchange/home',
    description: 'Equalizer was derived from the initial concept of Andre Cronje. The mechanism that was used to create a perpetual decentralized exchange was unique and ingenious. The launch of this platform is to take the concept and apply it to a more natural style DEX. Equalizer will become the trading mechanism for the Fantom network, utilizing the Solidly perpetual model with some tweaks to fee structures and emissions.',
    logoURI: '',
};
var PSCALE = {
    name: 'Pantheon Scale',
    symbol: 'PSCALE',
    address: '0xa662E00930bE69134f74fcB29faA39B843b3EeCE',
    chainId: 8453,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};
var PUBLX = {
    name: 'Publx',
    symbol: 'PUBLX',
    address: '0xD1f398d6B3E5e2387E413831e206CFEB5fc1dcEe',
    chainId: 8453,
    decimals: 18,
    website: '',
    description: '',
    logoURI: '',
};

var _tokens = {
    WETH: ETH,
    WNATIVE: ETH,
    ETH: ETH,
    BTC: BTC,
    SCALE: SCALE,
    PSCALE: PSCALE,
    USDC: USDC,
    USDBC: USDBC,
    PUBLX: PUBLX,
}
exports.tokens = _tokens;
