/* eslint-disable no-unused-vars */

Object.defineProperty(exports, "__esModule", { value: true });
exports.ChainId = void 0;

var ChainId;
(function (ChainId) {
    ChainId[ChainId["fantom"] = 250] = "fantom";
    ChainId[ChainId["base"] = 8453] = "base";
})(ChainId = exports.ChainId || (exports.ChainId = {}));
