import React, {useState} from 'react';
import Web3 from 'web3';
import { XpandrUnityABI } from '../../../../configure/abi.js';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const web3 = new Web3('https://fantom.publicnode.com');

const useStyles = makeStyles({
  linkContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'right',
  },
  link: {
    marginRight: '10px',
  },
  cReward: {
    marginLeft: '10px',
    marginBottom: '-5%',
    alignItems: 'left',
  },
  profits: {
    marginLeft: '1000px',
    marginBottom: '-5%',
    alignItems: 'center',
  }
});
let isMounted;

const LinkContainer = ({vaultAddress}) => {
  const classes = useStyles();
  const [callReward, setCallReward] = useState(0);
  const [profitCall, setProfit] = useState(0);
  const callRef = React.useRef(null);
  callRef.current = callReward;
  const profitRef = React.useRef(null);
  profitRef.current = profitCall;

  const fetchLinksAndRewards = React.useCallback(async () => {
    const vaultContract = new web3.eth.Contract(XpandrUnityABI, vaultAddress);
    const profit = await vaultContract.methods.vaultProfits().call();
    if(isMounted){
      setProfit(profit);
  }
    const call = await vaultContract.methods.callReward().call();
    const etherCall = web3.utils.fromWei(call, 'ether').slice(0, 5);
    if(isMounted)
{}    setCallReward(etherCall);
  },[vaultAddress]);


 React.useEffect(() => {
  isMounted = true;
    fetchLinksAndRewards();

    return () => {
      isMounted = false;
    };
  },[fetchLinksAndRewards, vaultAddress]);

  return (
 <Grid container className={classes.linkContainer}>
    <Grid item xs={12} sm={12}>
      <div className={classes.cReward}>
        <strong>Reward: {callRef.current} wFTM</strong>
      </div>
    </Grid>
    <Grid item xs={12} sm={12}>
  <div
    className={classes.profits}
    style={{
      marginLeft: '10px',
      textAlign: 'center',
      fontWeight: 'bold',
      color: 'white', // Set the text color to white
    }}
  >
    <strong>Vault Profit: <span style={{ color: 'green' }}>${profitRef.current}</span></strong>
  </div>
</Grid>    
<Grid item><div className={classes.link}><a
          href={`https://www.ftmscan.com/address/${vaultAddress}#contract`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'white', fontWeight: 'bold' }}>
          Vault Contract
        </a>
      </div>
    </Grid>
  </Grid>
);
};

export default LinkContainer;