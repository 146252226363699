Object.defineProperty(exports, "__esModule", { value: true });
exports.base = void 0;
var xpandrfinancial_1 = require("./platforms/xpandrfinancial");
var scale_1 = require("./platforms/scale");
var tokens_1 = require("./tokens/tokens");
var convertSymbolTokenMapToAddressTokenMap_1 = require("../../util/convertSymbolTokenMapToAddressTokenMap");
var _base = {
    platforms: {
        xpandrfinancial_1: xpandrfinancial_1.xpandrfinancial,
        scale: scale_1.scale,
    },
    tokens: tokens_1.tokens,
    tokenAddressMap: convertSymbolTokenMapToAddressTokenMap_1.convertSymbolTokenMapToAddressTokenMap(tokens_1.tokens),
};
exports.base = _base;
