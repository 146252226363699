export const fantomPools = [
  /*
  {
    id: 'equalizer-usdc-wftm',
    name: 'USDC-FTM LP',
    token: 'USDC-FTM LP (EQUALIZER)',
    tokenDescription: 'Equalizer',
    tokenAddress: '0x7547d05dFf1DA6B4A2eBB3f0833aFE3C62ABD9a1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpE-USDC-FTM',
    earnedTokenAddress: '0xb65D9aC2c8B92696FCff52AC9ddfe8e2658237B9',
    earnContractAddress: '0xb65D9aC2c8B92696FCff52AC9ddfe8e2658237B9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-usdc-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Equalizer',
    assets: ['USDC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://equalizer.exchange/liquidity/0x7547d05dFf1DA6B4A2eBB3f0833aFE3C62ABD9a1',
    buyTokenUrl:
      'https://equalizer.exchange/swap',
    createdAt: 64338593,
  },
  */

  {
    id: 'equalizer-equal-wftm',
    name: 'EQUAL-FTM',
    token: 'EQUAL-FTM LP (EQUALIZER)',
    tokenDescription: 'Equalizer',
    tokenAddress: '0x3d6c56f6855b7cc746fb80848755b0a9c3770122',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpE-EQUAL-FTM',
    earnedTokenAddress: '0x511Da9a9b93a0f8b5d3Edf60B8bfE6312B543685',
    earnContractAddress: '0x511Da9a9b93a0f8b5d3Edf60B8bfE6312B543685',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-equal-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Equalizer',
    assets: ['EQUAL', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://equalizer.exchange/liquidity/0x3d6c56f6855b7cc746fb80848755b0a9c3770122',
    buyTokenUrl:
      'https://equalizer.exchange/swap',
    createdAt: 73689546,
  },
  {
    id: 'equalizer-fbux-wftm',
    name: 'fBUX-FTM',
    token: 'fBUX-FTM LP (EQUALIZER)',
    tokenDescription: 'Equalizer',
    tokenAddress: '0xA45571a6B0861d3E4B26E0925ec2C8d9Cf44dC3A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpE-fBUX-FTM',
    earnedTokenAddress: '0x5A063480bC81d651D44Af97E02a786D5972FA42F',
    earnContractAddress: '0x5A063480bC81d651D44Af97E02a786D5972FA42F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-fbux-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Equalizer',
    assets: ['FBUX', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://equalizer.exchange/liquidity/0xA45571a6B0861d3E4B26E0925ec2C8d9Cf44dC3A',
    buyTokenUrl:
      'https://equalizer.exchange/swap',
    createdAt: 76448603,
  },
  {
    id: 'fmoney-fbux-usdc.e',
    name: 'fBUX-USDC.e',
    token: 'fBUX-USDC.e 80/20 LP (fMoney)',
    tokenDescription: 'fMoney',
    tokenAddress: '0x9426d9077620eFaAe688D3C3B398Fa814406cE4A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpfM-fBUX-USDC.e',
    earnedTokenAddress: '0x1cDB3bC1c5aA4353Eb1d87F7830Ce8e5dB5A09Ef',
    earnContractAddress: '0x1cDB3bC1c5aA4353Eb1d87F7830Ce8e5dB5A09Ef',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'fMoney-fbux-usdc.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'fMoney',
    assets: ['FBUX', 'USDCe'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://beets.fi/pool/0x9426d9077620efaae688d3c3b398fa814406ce4a000200000000000000000833',
    buyTokenUrl:
      'https://beets.fi/swap',
    createdAt: 81400151,
  },

  {
    id: 'equalizer-love-wftm',
    name: 'LOVE-FTM',
    token: 'LOVE-FTM LP (EQUALIZER)',
    tokenDescription: 'Equalizer',
    tokenAddress: '0x6d3E93beE1123daA00B8cE3914BB521dd1B718dE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpE-LOVE-FTM',
    earnedTokenAddress: '0x3a7794Fda370aF3dBd8444fAe783489f22555567',
    earnContractAddress: '0x3a7794Fda370aF3dBd8444fAe783489f22555567',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-love-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Equalizer',
    assets: ['LOVE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://equalizer.exchange/liquidity/0x6d3E93beE1123daA00B8cE3914BB521dd1B718d',
    buyTokenUrl:
      'https://equalizer.exchange/swap',
    createdAt: 76815506,
  },
  {
    id: 'equalizer-thc-wftm',
    name: 'THC-FTM',
    token: 'THC-FTM LP (EQUALIZER)',
    tokenDescription: 'Equalizer',
    tokenAddress: '0x88477652F12a564e7811AcA7108472d3ef54C5E1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpE-THC-FTM',
    earnedTokenAddress: '0xE0577a868C079ccb8675BEacE1C66e405D08584a',
    earnContractAddress: '0xE0577a868C079ccb8675BEacE1C66e405D08584a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-thc-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Equalizer',
    assets: ['THC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://equalizer.exchange/liquidity/0x88477652F12a564e7811AcA7108472d3ef54C5E1',
    buyTokenUrl:
      'https://equalizer.exchange/swap',
    createdAt: 77278116,
  },

  {
    id: 'equalizer-fghst-equal',
    name: 'fGHST-EQUAL',
    token: 'fGHST-EQUAL LP (EQUALIZER)',
    tokenDescription: 'Equalizer',
    tokenAddress: '0x3f7be927d13dc45D7A6DEbaD5E6d96f4c35DaD90',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpE-fGHST-EQUAL',
    earnedTokenAddress: '0xbB01cEf534657Ed52d4d800d02bFAc56B4F78EFb',
    earnContractAddress: '0xbB01cEf534657Ed52d4d800d02bFAc56B4F78EFb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-fghst-equal',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Equalizer',
    assets: ['FGHST', 'EQUAL'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://equalizer.exchange/liquidity/0x3f7be927d13dc45D7A6DEbaD5E6d96f4c35DaD90',
    buyTokenUrl:
      'https://equalizer.exchange/swap',
    createdAt: 73693663,
  },
  {
    id: 'equalizer-gmftm-wftm',
    name: 'GMFTM-FTM',
    token: 'GMFTM-FTM LP (EQUALIZER)',
    tokenDescription: 'Equalizer',
    tokenAddress: '0xb6bc2682F196c1Fd32BD6b0DE4Af7696A3bb83F9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpE-GMFTM-FTM',
    earnedTokenAddress: '0x4E7fAAd617e348247a38eEe08346d27dEeD83DAb',
    earnContractAddress: '0x4E7fAAd617e348247a38eEe08346d27dEeD83DAb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-gmftm-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Equalizer',
    assets: ['GMFTM', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://equalizer.exchange/liquidity/0xb6bc2682F196c1Fd32BD6b0DE4Af7696A3bb83F9',
    buyTokenUrl:
      'https://equalizer.exchange/swap',
    createdAt: 74809029,
  },
  {
    id: 'equalizer-chill-wftm',
    name: 'CHILL-FTM',
    token: 'CHILL-FTM LP (EQUALIZER)',
    tokenDescription: 'Equalizer',
    tokenAddress: '0x11a0779eA92176298b7A2760AE29fC9Ce1aD47b4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpE-CHILL-FTM',
    earnedTokenAddress: '0x26F293aB3814B79E104A7Df05cd6c1d7945F288a',
    earnContractAddress: '0x26F293aB3814B79E104A7Df05cd6c1d7945F288a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-chill-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Equalizer',
    assets: ['CHILL', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://equalizer.exchange/liquidity/0x11a0779eA92176298b7A2760AE29fC9Ce1aD47b4',
    buyTokenUrl:
      'https://equalizer.exchange/swap',
    createdAt: 73693091,
  },
  {
    id: 'equalizer-kirby-wftm',
    name: 'KIRBY-FTM',
    token: 'KIRBY-FTM LP (EQUALIZER)',
    tokenDescription: 'Equalizer',
    tokenAddress: '0xaba325fe915ab7e3d58ca3917972ae7952f31987',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpE-KIRBY-FTM',
    earnedTokenAddress: '0xA993CC36174f668082D6F809d3EdE685d8eAe43a',
    earnContractAddress: '0xA993CC36174f668082D6F809d3EdE685d8eAe43a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-kirby-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Equalizer',
    assets: ['KIRBY', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://equalizer.exchange/liquidity/0xCD1dA19973e77f107f58f6Ce9F98476e88b9B36c',
    buyTokenUrl:
      'https://equalizer.exchange/swap',
    createdAt: 73691285
  },
  {
    id: 'equalizer-fknuckles-wftm',
    name: 'fKNUCKLES-FTM',
    token: 'fKNUCKLES-FTM LP (EQUALIZER)',
    tokenDescription: 'Equalizer',
    tokenAddress: '0xD9Cb02ad1D3fD66a33172A8BFfF7Fcee774F7c0F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpE-FKNUCKLES-FTM',
    earnedTokenAddress: '0x7e7e1c62c10e89f591115e1C80Aeeb8D6e433A8C',
    earnContractAddress: '0x7e7e1c62c10e89f591115e1C80Aeeb8D6e433A8C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-fknuckles-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Equalizer',
    assets: ['FKNUCKLES', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://equalizer.exchange/liquidity/0xD9Cb02ad1D3fD66a33172A8BFfF7Fcee774F7c0F',
    buyTokenUrl:
      'https://https://equalizer.exchange/swap',
    createdAt: 74008100,
  },
  {
    id: 'equalizer-goat-wftm',
    name: 'GOAT-FTM',
    token: 'GOAT-FTM LP (EQUALIZER)',
    tokenDescription: 'Equalizer',
    tokenAddress: '0x0312Ac51857ba0a5b696C18beE5978FddED70904',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpE-GOAT-FTM',
    earnedTokenAddress: '0x18dE1466c8c1d12669A8c87Fe46d73bb180A2299',
    earnContractAddress: '0x18dE1466c8c1d12669A8c87Fe46d73bb180A2299',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'equalizer-goat-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Equalizer',
    assets: ['GOAT', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://equalizer.exchange/liquidity/0x0312Ac51857ba0a5b696C18beE5978FddED70904',
    buyTokenUrl:
      'https://https://equalizer.exchange/swap',
    createdAt: 75541634,
  },
  {
    id: 'wigoswap-wigo-wftm',
    name: 'WIGO-FTM',
    token: 'WIGO-FTM LP (WIGOSWAP)',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0xB66E5c89EbA830B31B3dDcc468dD50b3256737c5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpW-WIGO-FTM',
    earnedTokenAddress: '0x74aE189aE6Fe56Aa2BfCB46cCec8FdF82F8fd574',
    earnContractAddress: '0x74aE189aE6Fe56Aa2BfCB46cCec8FdF82F8fd574',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigoswap-wigo-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['WIGO', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://wigoswap.io/add',
    buyTokenUrl:
      'https://wigoswap.io/swap',
    createdAt: 74938876,
  },
  {
    id: 'wigoswap-usdce-wftm',
    name: 'USDC.e-FTM',
    token: 'USDC.e-FTM LP (WIGOSWAP)',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0x2D0Ed226891E256d94F1071E2F94FBcDC9060E14',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpW-USDC.e-FTM',
    earnedTokenAddress: '0x5e63714C1bF7254203689fbb4f06d7605D0245f1',
    earnContractAddress: '0x5e63714C1bF7254203689fbb4f06d7605D0245f1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigoswap-usdce-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['USDCe', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://wigoswap.io/add',
    buyTokenUrl:
      'https://wigoswap.io/swap',
    createdAt: 81406387,
  },
  {
    id: 'wigoswap-axlusdc-wftm',
    name: 'axlUSDC-FTM',
    token: 'axlUSDC-FTM LP (WIGOSWAP)',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0x084F933B6401a72291246B5B5eD46218a68773e6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpW-axlUSDC-FTM',
    earnedTokenAddress: '0xB2cBc3D1da5Fc9D77D2e9a53DB47CbEFf0aB50B5',
    earnContractAddress: '0xB2cBc3D1da5Fc9D77D2e9a53DB47CbEFf0aB50B5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigoswap-axlusdc-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['USDC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://wigoswap.io/add',
    buyTokenUrl:
      'https://wigoswap.io/swap',
    createdAt: 75255503,
  },
  {
    id: 'wigoswap-mim-wftm',
    name: 'MIM-FTM',
    token: 'MIM-FTM LP (WIGOSWAP)',
    tokenDescription: 'WigoSwap',
    tokenAddress: '0x92A164d177552d483E3abBc6fA250bE86be481D7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'XpW-MIM-FTM',
    earnedTokenAddress: '0x3B32d1a03b77a4B6a19A966a6E50FA93eF547217',
    earnContractAddress: '0x3B32d1a03b77a4B6a19A966a6E50FA93eF547217',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wigoswap-mim-wftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'WigoSwap',
    assets: ['MIM', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://wigoswap.io/add',
    buyTokenUrl:
      'https://wigoswap.io/swap',
    createdAt: 75259875,
  }
];

