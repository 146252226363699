export const nativeCoins = [
  /*{
    chainId: 1,
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    wrappedSymbol: 'WETH',
    allowance: Infinity,
  },
  {
    chainId: 56,
    name: 'Binance Coin',
    symbol: 'BNB',
    decimals: 18,
    wrappedSymbol: 'WBNB',
    allowance: Infinity,
  },
  {
    chainId: 128,
    name: 'Heco Token',
    symbol: 'HT',
    decimals: 18,
    wrappedSymbol: 'WHT',
    allowance: Infinity,
  },
  {
    chainId: 42220,
    name: 'Celo',
    symbol: 'CELO',
    decimals: 18,
    wrappedSymbol: 'CELO',
    allowance: Infinity,
  },
  {
    chainId: 43114,
    name: 'Avalance Coin',
    symbol: 'AVAX',
    decimals: 18,
    wrappedSymbol: 'WAVAX',
    allowance: Infinity,
  },
  {
    chainId: 137,
    name: 'Polygon',
    symbol: 'MATIC',
    decimals: 18,
    wrappedSymbol: 'WMATIC',
    allowance: Infinity,
  },
*/{
    chainId: 250,
    name: 'Fantom',
    symbol: 'FTM',
    decimals: 18,
    wrappedSymbol: 'WFTM',
    allowance: Infinity,
  },
  
  {
    chainId: 8453,
    name: 'Base',
    symbol: 'ETH',
    decimals: 18,
    wrappedSymbol: 'WETH',
    allowance: Infinity,
  },
  /*
  {
    chainId: 42161,
    name: 'Arbitrum',
    symbol: 'ETH',
    decimals: 18,
    wrappedSymbol: 'WETH',
    allowance: Infinity,
  },
  {
    chainId: 1285,
    name: 'Moonriver',
    symbol: 'MOVR',
    decimals: 18,
    wrappedSymbol: 'WMOVR',
    allowance: Infinity,
  },
  {
    chainId: 25,
    name: 'Cronos',
    symbol: 'CRO',
    decimals: 18,
    wrappedSymbol: 'WCRO',
    allowance: Infinity,
  },
  {
    chainId: 122,
    name: 'Fuse',
    symbol: 'FUSE',
    decimals: 18,
    wrappedSymbol: 'WFUSE',
    allowance: Infinity,
  },
  {
    chainId: 1088,
    name: 'Metis',
    symbol: 'METIS',
    decimals: 18,
    wrappedSymbol: 'WMETIS',
    allowance: Infinity,
  },
  {
    chainId: 1313161554,
    name: 'Aurora',
    symbol: 'ETH',
    decimals: 18,
    wrappedSymbol: 'WETH',
    allowance: Infinity,
  },
  {
    chainId: 1284,
    name: 'Moonbeam',
    symbol: 'GLMR',
    decimals: 18,
    wrappedSymbol: 'WGLMR',
    allowance: Infinity,
  },
  {
    chainId: 42262,
    name: 'Oasis Emerald',
    symbol: 'ROSE',
    decimals: 18,
    wrappedSymbol: 'WROSE',
    allowance: Infinity,
  },
*/
];
