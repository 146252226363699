Object.defineProperty(exports, "__esModule", { value: true });
exports.fantom = void 0;
var xpandrfinancial_1 = require("./platforms/xpandrfinancial");
var equalizer_1 = require("./platforms/equalizer");
var beethovenx_1 = require('./platforms/beethovenx');
var spookyswap_1 = require('./platforms/spookyswap');
var fmoneystake_1 = require('./platforms/fmoneystake');
var wigoswap_1 = require('./platforms/wigoswap');
var tokens_1 = require("./tokens/tokens");
var convertSymbolTokenMapToAddressTokenMap_1 = require("../../util/convertSymbolTokenMapToAddressTokenMap");
var _fantom = {
    platforms: {
        xpandrfinancial_1: xpandrfinancial_1.xpandrfinancial,
        equalizer: equalizer_1.equalizer,
        beethovenx: beethovenx_1.beethovenx,
        spookyswap: spookyswap_1.spookyswap,
        wigoswap: wigoswap_1.wigoswap,
        fmoneystake: fmoneystake_1.fmoneystake
    },
    tokens: tokens_1.tokens,
    tokenAddressMap: convertSymbolTokenMapToAddressTokenMap_1.convertSymbolTokenMapToAddressTokenMap(tokens_1.tokens),
};
exports.fantom = _fantom;
